import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Alert, Box, Button, Chip, Stack, Typography } from "@mui/material"
import { STATUS_CHIP_INFO } from './constants'
import { PlanStatus } from '@datagrab/datagrab-common/constants'
import { Metric } from './Metric'
import { getTotalPrice } from './utils'


const useStyles = makeStyles((theme) => ({
    wrapper: {
        color: theme.palette.grey[800],
    },
    subscription: {
        '&:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            marginBottom: theme.spacing(5)
        },
        '&.highlight': {
            background: theme.palette.gold.light,
            padding: theme.spacing(2),
            borderRadius: theme.borderRadius[3]
        }
    },
    noSubNotice: {
        marginTop: 0,
        marginBottom: theme.spacing(6)
    },
    name: {
        marginTop: 0,
        color: theme.palette.grey[900],
        fontSize: theme.fontSize[5]
    },
    body: {
        fontSize: theme.fontSize[2]
    }
}))

/**
 * Displays the user's custom plans, allows managing them.
 */
export const CustomPlansPanel = ({ subscriptions, onAcceptOffer = f => f,  onManageSubscription = f => f }) => {
    const classes = useStyles()

    return <div className={classes.wrapper}>
        {subscriptions.map((subscription, i) => <div className={`${classes.subscription} ${subscription.status === PlanStatus.OFFERED ? 'highlight' : ''}`}>
            <h3 className={classes.name}>{subscription.name}</h3>
            <Chip color={STATUS_CHIP_INFO[subscription.status].color}
                label={STATUS_CHIP_INFO[subscription.status].label}
                size='small' />

            {subscription.status === PlanStatus.CANCELLING &&
                <Box my={5}><Alert variant="outlined" severity="error">You have cancelled your subscription, but you can still renew it
                    until <strong>{!subscription || !subscription.periodEnd ? null : new Date(subscription.periodEnd).toDateString()}</strong> if you change your mind.</Alert></Box>}

            {subscription.status === PlanStatus.SUSPENDED &&
                <Box my={5}><Alert variant="outlined" severity="error">Your subscription is suspended because the last payment had failed. Please
                    update your payment method.</Alert></Box>}

            <Box display="flex" justifyContent="space-between" my={6}>
                {subscription.status !== PlanStatus.OFFERED && <Stack spacing={6}>
                    <Metric label={subscription.metered ? 'Current Usage' : 'Credits Used'} value={`${subscription.currentUsage}${subscription.metered ? '' : '/' + subscription.monthlyCredits.toLocaleString()}`} />

                    {(subscription.status !== PlanStatus.CANCELLING) && <div>
                        <Metric label="Next Payment" value={`$${getTotalPrice(subscription).toFixed(2)}`} color="success" />
                        <Typography variant="body2">on <strong>{!subscription || !subscription.periodEnd ? null : new Date(subscription.periodEnd).toDateString()}</strong></Typography>
                    </div>}
                </Stack>}

                <Stack spacing={6}>
                    <div>
                        {subscription.created && <Typography variant="body2">Created on <strong>{new Date(subscription.created).toDateString()}</strong></Typography>}
                        <Typography variant="body2"><strong>${subscription.unitPrice} / {subscription.metered ? 'request' : 'month'}</strong>, billed {subscription.billingInterval}</Typography>
                        {subscription.monthlyCredits && <Typography variant="body2"><strong>{subscription.monthlyCredits.toLocaleString()}</strong> credits / month</Typography>}
                        {subscription.concurrency && <Typography variant="body2"><strong>{subscription.concurrency}</strong> concurrent requests / run</Typography>}
                    </div>

                    {subscription.status !== PlanStatus.OFFERED && <Button variant="outlined" color="primary" onClick={onManageSubscription}>Manage Subscription</Button>}
                </Stack>

                {subscription.status === PlanStatus.OFFERED && <Button size="large" variant="contained" color='primary' onClick={() => onAcceptOffer(subscription.stripePriceId)}>Accept Offer</Button>}
            </Box>
        </div>)}
    </div>
}